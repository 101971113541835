import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import GraphQLErrorList from "../components/BlogPost/graphql-error-list";
import BlogPost from "../components/BlogPost";
import Seo from "../components/SEO";

const BlogPostTemplate = (props) => {
  const { data, errors } = props;
  const post = data && data.post;
  const site = data && data.site;
  return (
    <Layout>
      {errors && <Seo title="GraphQL Error" />}
      {post && <Seo title={post.seoTitle} description={post.metaDescription} />}

      {errors && (
        <div className="container">
          <GraphQLErrorList errors={errors} />
        </div>
      )}

      {post && (
        <>
          <BlogPost {...post} {...site} />
        </>
      )}
    </Layout>
  );
};

export default BlogPostTemplate;

export const query = graphql`
  query BlogPostQuery($id: String!) {
    post: sanityBlogs(id: { eq: $id }) {
      id
      publishedAt
      featuredImage {
        asset {
          gatsbyImageData
          url
        }
      }
      title
      slug {
        current
      }
      seoTitle
      metaDescription
      _rawBody(resolveReferences: { maxDepth: 5 })
      author {
        name
        image {
          asset {
            gatsbyImageData
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
